<template>
  <aside class="user-sidebar-jp">

    <div class="core-section">
      <section class="fav-search mb-3">
        <strong class="widget-title">
          <CIcon
            size="lg"
            class="m-0 p-0  mr-2 text-green"
            name="cil-home"
          />
          宿名
        </strong>
        <CInput
          class="search-input"
          :placeholder="$t('facilitySearchPage.facilityNamePlaceholderText')"
          v-model="query.facilityName"
          type="text"
          @keydown.enter="onEnterKeyPress($event, 'facilityName')"
        >
          <template #append>
            <CButton
              type="submit"
              color="green"
              @click="onSearchClicked('facilityName')"
              :disabled="!query.facilityName"
            >
              <CIcon
                size="lg"
                class="m-0 p-0"
                name="cil-magnifying-glass"
              />
            </CButton>
            <!-- <i class="icon cil-magnifying-glass"></i> -->
          </template>
        </CInput>

      </section>
      <hr class="line mt-4 mb-3" />

      <!-- #area dropdow region -->
      <section class="area-filter-section">
        <strong class="widget-title">
          <CIcon
            size="lg"
            class="text-green p-0 m-0  mr-2"
            name="cil-location-pin"
          />
          エリア
        </strong>
        <span
          @click="areaListBox = !areaListBox"
          class="mb-2 d-block"
        >
          <CInput
            class="area-input-f"
            type="email"
            disabled
            :class="selectedAreaInfo.areaId ? 'area-selected' : ''"
            :placeholder="selectedAreaInfo.areaId ? selectedAreaInfo.name: 'エリアを選択'"
            autocomplete="email"
          >
            <template #prepend>
              <span class="pin-icon">

              </span>
            </template>
          </CInput>
        </span>

        <CCard
          v-if="areaListBox"
          class="area-list-box shadow-sm"
          :key="reLoad"
        >
          <section class="area-list-group">
            <CCardBody>
              <ul class="prefecture-list">
                <li
                  class="area-list"
                  v-for="area in fullAreaList"
                  :key="area.areaId"
                >

                  <span
                    class="core-area d-block"
                    @click="area.display=!area.display"
                  > {{area.name}}

                    <i
                      class=" bi float-right"
                      :class="area.display ? 'bi-chevron-down':'bi-chevron-right'"
                    ></i>
                  </span>
                  <transition name="slide">
                    <ul
                      class="ken-list"
                      v-if="area.display"
                    >
                      <li
                        v-for="ken in area.childs"
                        :key="ken.areaId"
                      >
                        <span class="ken-title">
                          <label
                            :for="'flexCheckChecked'+ken.areaId"
                            class="ml-1 my-1"
                          >
                            <input
                              type="radio"
                              :id="'flexCheckChecked'+ken.areaId"
                              v-model="selectedAreaInfo"
                              v-bind:value="ken"
                              @click="selecteArealC(ken)"
                            >

                            {{ken.name}}
                          </label>
                          <i
                            class="bi float-right mr-1 w-25 text-right"
                            @click="displayCity(ken)"
                            :class="ken.display ?  'bi-chevron-down':'bi-chevron-right'"
                          ></i>
                          <transition name="slide">
                            <ul
                              class="city-list"
                              v-if="ken.display"
                            >
                              <li
                                v-for="city in ken.childs"
                                :key="city.areaId"
                              >
                                <span class="city-span">
                                  <label
                                    :for="'flexCheckChecked'+city.areaId"
                                    class="ml-1 city-name "
                                  >
                                    <input
                                      type="radio"
                                      v-model="selectedAreaInfo"
                                      v-bind:value="city"
                                      :id="'flexCheckChecked'+city.areaId"
                                      class="city-check"
                                      @click="selecteArealC(city)"
                                    >
                                    <span>{{city.name}}</span>
                                  </label>
                                  <i
                                    v-if="city.childs.length>0"
                                    class="bi f mr-1 w-25 text-right"
                                    @click="displayCity(city)"
                                    :class="city.display ?  'bi-chevron-down':'bi-chevron-right'"
                                  ></i>
                                </span>
                                <transition name="slide">
                                  <ul
                                    class="city-list"
                                    v-if="city.display"
                                  >
                                    <li
                                      v-for="ward in city.childs"
                                      :key="ward.areaId"
                                    >
                                      <label
                                        :for="'flexCheckChecked'+ward.areaId"
                                        class="ml-1 city-name"
                                      >
                                        <input
                                          type="radio"
                                          v-model="selectedAreaInfo"
                                          v-bind:value="ward"
                                          :id="'flexCheckChecked'+ward.areaId"
                                          class="city-check"
                                          @click="selecteArealC(ward)"
                                        >
                                        <span>{{ward.name}}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </transition>
                              </li>
                            </ul>
                          </transition>
                        </span>
                      </li>
                    </ul>
                  </transition>
                </li>
              </ul>

              <CSpinner
                color="info"
                class="mx-auto d-block my-4"
                v-if="spinnerSide"
              />
            </CCardBody>
            <CCardFooter>
              <CButton
                variant="outline"
                color="dark"
                @click="areaListBox= false"
              >
                {{$t('common.close')}}
              </CButton>
              <CButton
                color="green"
                class="float-right"
                :disabled="!selectedAreaInfo.areaId"
                @click="filterByArea()"
              >
                検索する
              </CButton>

            </CCardFooter>
          </section>
          <div
            class="area-overlay-section"
            @click="areaListBox= false"
          ></div>
        </CCard>

      </section>
      <!-- #end area dropdow region -->
      <hr class="line mt-4 mb-3" />

      <section class="kodawari">
        <strong class="list-title">
          <CIcon
            size="lg"
            class="m-0 p-0 mr-2 text-green"
            name="cil-tags"
          />
          タグ
        </strong>
        <!-- こだわり button secton  -->
        <CButton
          color="green"
          block
          class="mt-2 mb-3"
          variant="outline"
          @click="tagListModal = true"
        >
          <strong>
            <!-- こだわりをすべてみる -->
            タグを選択
          </strong>
        </CButton>

        <CModal
          color="dark"
          :centered="true"
          :closeOnBackdrop="false"
          :show.sync="tagListModal"
          size="lg"
        >
          <div class="kodawari-body">
            <TagList
              @selected-tags="selectedTags = $event"
              :key="reloadTags"
            />
            <!-- <pre><code>{{selectedTags}}</code></pre> -->
          </div>
          <template #header>
            {{ $t("yadoEdit.facilityTag.title") }}
          </template>
          <template #footer>
            <CButton
              @click="tagListModal = false"
              variant="outline"
              color="dark"
            >
              {{$t('common.close')}}
            </CButton>
            <CButton
              color="dark"
              @click="clearTags()"
            >
              <i class="icon cil-clear-all"></i> {{$t('common.clear')}}
            </CButton>
            <CButton
              color="green"
              :disabled="selectedTags.length==0"
              @click="filterByTag()"
            >
              検索する
            </CButton>
          </template>
        </CModal>
      </section>
    </div>
    <!-- core section end  -->
  </aside>
</template>
<script>
import TagList from "./TagList";
import axios from "axios";

export default {
  components: { TagList },
  props: {
    areaData: {
      type: Object,
    },
  },
  data() {
    return {
      areaListBox: false,
      selectDateBox: false,
      tagListModal: false,
      favoriteSearchModal: false,
      spinnerSide: true,
      selectedTags: [],
      fullAreaList: [],
      reloadTags: 0,
      reLoad: 0,
      test: [],
      selectedAreaInfo: {},
      /** 検索クエリ */
      query: {
        facilityName: "",
      },
    };
  },

  methods: {
    /**エンターキー押下 */
    onEnterKeyPress: function (event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode !== 13) return;
      switch (value) {
        case "facilityName":
          if (!this.query.facilityName) return;
          break;
        default:
          return;
      }
      this.onSearchClicked(value);
    },

    /** 検索ボタン押下 */
    onSearchClicked(value) {
      // 宿検索API URL
      let urlRequest;

      switch (value) {
        case "facilityName":
          urlRequest = this.makeUrlRequest(value, this.query.facilityName);
          break;
      }

      if (!urlRequest) {
        // "検索条件を入力してください"
        alert(this.$i18n.t("facilitySearchPage.noQueryAlert"));
        return;
      }

      this.$parent.getFacilities(urlRequest, this.query.facilityName);
      if (this.$route.query.areaId) this.$router.replace({ query: null });
      this.selectedAreaInfo = {};
      // this.$parent.getFacilitiesListByArea(this.selectedAreaInfo);
    },

    //#region method

    /**
     * リクエストURL作成
     * @param key 検索クエリキー
     * @param value 検索クエリ値
     */
    makeUrlRequest(key, value) {
      const isKeyAndValue = !key || !value;
      return isKeyAndValue ? "" : `/rest/hotel/published?${key}=${value}`;
      // return isKeyAndValue ? "" : `http://localhost:8080/rest/hotel/?${key}=${value}`;
    },

    //#endregion request

    getAllAreaList() {
      var urlRequest = `/rest/area/arealist`;
      axios
        .get(urlRequest)
        .then((resp) => {
          console.log("areaList:" + JSON.stringify(resp.data.areas));
          var areList = resp.data.areas;
          this.fullAreaList = areList.map((a) => {
            a.display = false;
            if (a.childs) {
              a.childs.forEach((b) => {
                b.display = false;
                if (b.childs) {
                  b.childs.forEach((c) => {
                    c.display = false;
                    return c;
                  });
                }
                return b;
              });
            }
            return a;
          });
          this.spinnerSide = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    displayCity(ken) {
      ken.display = !ken.display;
    },

    getList() {
      // this.test = this.fullAreaList;
    },
    /** button: request by area list */
    filterByArea() {
      if (this.$route.query.areaId) this.$router.replace({ query: null });

      this.$parent.getFacilitiesListByArea(this.selectedAreaInfo);
      this.areaListBox = false;
      this.reLoad++;
      this.query.facilityName = "";
    },

    /** button: request by tag list */
    filterByTag() {
      if (this.$route.query.areaId) this.$router.replace({ query: null });

      this.$parent.getFacilitiesListByTags(this.selectedTags);

      this.areaListBox = false;
      this.reLoad++;
      this.query.facilityName = "";
      this.selectedAreaInfo.name = "";
    },
    /** Button: open area list modal */
    openAreaModal() {},
    /** input: top search field modal */
    topSearch() {
      this.favoriteSearchModal = true;
    },

    simple_toggle(default_limit, filters_length) {
      this.limit_by =
        this.limit_by === default_limit ? filters_length : default_limit;
    },
    clearTags() {
      this.selectedTags = [];
      this.reloadTags++;
    },
    selecteArealC(area) {
      console.log(area);
      this.selectedAreaInfo = area;
      // if (this.selectedAreaInfo) this.areaListBox = !this.areaListBox;
    },
  },
  beforeMount() {
    this.getAllAreaList();
    this.selectedAreaInfo = this.areaData;
  },
};
</script>
<style lang="scss" scoped>
.user-sidebar-jp {
  .core-section {
    // background: #eeeeee;
    padding: 10px 10px 10px 0;
    border-radius: 5px;
    .widget-title {
      margin-bottom: 5px;
      display: block;
    }
    // area-filter-sectoin

    .area-list-box {
      position: absolute;
      border: 1px solid #cccccc;
      min-width: 400px;
      background: #ffffff;
      z-index: 2;
    }
    .core-filter-section {
      position: relative;

      .select-date {
        position: relative;
        .custom-datepicker {
          position: absolute;
          z-index: 2;
          left: 0px;
          top: 50px;
        }
      }
    }
  }

  .map-filter {
    margin: 1.2em 0;
    .map-box {
      border: 1px solid #31addf;
      background: #daf0db;
      padding: 2em 1em;
      .c-icon {
        display: block;
        margin: 0 auto;
        font-size: 2em;
      }
      strong {
        display: block;
        text-align: center;
      }
    }
  }
}

.tag-list {
  display: block;
  li {
    display: inline-block;
    background: #d8eed3;
    border: 1px solid #6bb461;
    border-radius: 5px;
    color: #222222;
    padding: 0 5px;
  }
}
.kodawari-body {
  .list-title {
    display: block;
  }
  .kodawari-list {
    display: block;
    width: 100%;
    margin: 0 0 1rem 0;
    li {
      display: inline-block;
      width: 30%;
      border: 1px solid #cccccc;
      margin: 1%;
      padding: 4px 0;
      border-radius: 10px;
      text-align: center;
      cursor: pointer;

      &:hover,
      &.active {
        background: #31addf;
        border: 1px solid #31addf;

        a,
        span {
          color: #ffffff;
        }
      }
      a {
        color: #31addf;
        display: inline-block;
        margin-right: 4px;
      }
      span {
        color: #cccccc;
        font-size: 0.9em;
      }
    }
  }
}
.fav-search {
  position: relative;
  .modal-content-section {
    position: absolute;
    background: #ffffff;
    display: block;
    z-index: 9;
    min-width: 400px;
    max-height: 300px;
    overflow-y: auto;
    padding: 1rem;
    .modal-title {
      font-size: 1.2em;
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .fav-list {
      margin: 0 0 15px 20px;
      li {
        margin: 5px 0;
        font-size: 1.2em;
      }
    }
  }
}

.overlayClose {
  // background: pink;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}
.delete-me {
  width: 100%;
  position: absolute;
  right: -600px;
  top: 0;
  z-index: 999;
  background: white;
}
.prefecture-list {
  max-height: 46vh;
  overflow-y: auto;
  label,
  .core-area,
  .bi {
    cursor: pointer;
    margin: 0 !important;
  }
  li {
    clear: both;

    &.area-list {
      margin-bottom: 2px;
    }
    ul {
      margin-left: 20px;
    }
    .city-list {
      li {
        border-bottom: 1px solid #cccccc;
        display: block;
        margin-bottom: 2px;
        &:last-child {
          border: none;
        }
        label {
          margin: 0 !important;
          padding: 0 !important;
        }
        .city-span {
          .city-name {
            // display: flex;
            display: inline-block;
            width: 90%;
            .city-check {
              margin: 7px 5px 0 0;
            }
          }
          .bi {
            display: inline-block;
            vertical-align: top;
            // background: red;
            width: 10% !important;
            text-align: right !important;
          }
        }
      }
    }
    .ken-list {
      .ken-title {
        border-bottom: 1px solid #cccccc;
        display: block;
        // border-radius: 4px;
        padding: 0;
        margin-top: 2px;
        label {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
}
.core-area {
  border-bottom: 1px solid #cccccc;
  display: block;
  // border-radius: 4px;
  padding: 0;
  .ic {
    margin-top: 5px;
  }
}

.pin-icon {
  background: #ffffff;
  border-top: 1px solid #d8dbe0;
  border-bottom: 1px solid #d8dbe0;
  border-left: 1px solid #d8dbe0;
  border-right: none;
  position: relative;
  padding-left: 8px;
}

.selected-tags {
  li {
    display: inline-block;
    font-size: 0.8em;
    text-align: center;
    border: 1px solid #cccccc;
    background: #ffffff;
    padding: 2px 4px 0;
    border-radius: 3px;
    margin: 0 2px 0 0;
    line-height: 1em;
    .s-l {
      display: inline-block;
      font-size: 1.2em;
      line-height: 1em;
    }
  }
}

.btn-outline-green.disabled strong {
  color: #ffffff;
}
// animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 300px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

// overlay section
.area-overlay-section {
  // background: pink;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}
.area-list-group {
  position: relative;
  z-index: 99;
  background: #ffffff;
}
</style>