<template>
  <CCard class="yado-intro-content border-0">

    <CCardHeader
      borderColor="white"
      class="p-0"
    >
      <CCardTitle class="cards-title p-0">
        <div class="title-favorit">
          <div class="title-section ">
            <h4 class="pl-2 mt-2">{{FacilityInfo.name}}</h4>
          </div>
        </div>
        <p class="pronunciation pl-2">{{FacilityInfo.pronunciation}}</p>

      </CCardTitle>
    </CCardHeader>
    <CCardBody class="px-0 pt-0 pb-3 m-0 border-bottom">
      <CRow class="m-0 p-0">
        <CCol class="p-0">

          <div
            class="feature-img-intro"
            v-lazy:background-image="imgMd+IntroImg"
          >
          </div>
        </CCol>
        <CCol class="px-3 py-0 details-sec">
          <p>
            <CIcon
              class="text-mikan"
              name="cil-location-pin"
            />{{addressInfo(FacilityInfo)}}
          </p>
          <p class="facility-details">{{FacilityInfo.desciption}}</p>

          <CButton
            color="info"
            size="sm"
            variant="outline"
            class="show-details-btn"
            @click="onFacilityViewPage(FacilityInfo.uniqueId)"
          >{{$t("common.details")}}</CButton>
        </CCol>
      </CRow>

    </CCardBody>

  </CCard>
  <!-- FacilityList card end  -->
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
  name: "FacilityList",
  props: {
    FacilityInfo: {
      type: Object,
    },
  },
  data() {
    return {
      facilityNotFound: false,
      facilitiesLoading: false,
      facility: {},
      IntroImg: "",
      ImgUrl: config.IMAGE_URL,
      imgMd: config.IMAGE_MD_URL, // medium size

    };
  },
  // created() {
  //   this.UniqueId = this.$route.params.uniqueId;
  // },
  methods: {
    /**
     * get faciliy information by id
     */
    getFacilityInformation() {
      var urlRequest = "/rest/hotel/" + this.FacilityId;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.facility = resp.data;
          this.getPropertyImg();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * get property images
     */
    getPropertyImg() {
      var urlRequest =
        "/rest/hotel/" + this.FacilityInfo.uniqueId + "/detail/24";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          // var Getproperties = resp.data.properties;
          // this.IntroImg = Getproperties.filter((e) => e.propertyId == 24)[0];
          var firstImg = resp.data.images;
          this.IntroImg = firstImg ? firstImg[0].fileName : " ";
          console.log("dd" + JSON.stringify(this.IntroImg));

          // this.refreshProperty();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /** 宿一覧の編集ボタン押下 */
    onFacilityViewPage(uniqueId) {
      this.$router.push({
        name: "FacilityDetail",
        params: { uniqueId: uniqueId },
      });
    },

    /**Button: go to details page */
    addressInfo(facility) {
      var address =
        "〒" +
        facility.zipcode +
        " " +
        (facility.address1 ? facility.address1 : "") +
        (facility.address2 ? " + " + facility.address2 : "");
      return address;
    },
  },
  beforeMount() {
    // this.getFacilityInformation();
    this.getPropertyImg();
  },
};
</script>

<style lang="scss" >
.yado-intro-content {
  .title-section {
    h4 {
      font-size: 2rem !important;
      font-weight: bold;
    }
  }
}
.facility-img {
  height: 20vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
}
.details-sec {
  position: relative;
  .facility-details {
    margin-bottom: 30px;
  }
}
.show-details-btn {
  position: absolute;
  bottom: 0;
  width: 93%;
}
.pronunciation {
  clear: both;
  font-weight: normal;
  font-size: 0.8em;
  margin: 0 0 5px 0;
}

.filter-navigation-section {
  clear: both;
  padding-top: 80px;
  border-bottom: 1px solid #cccccc;
  .filtet-nav {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto -1px;

    .col {
      text-align: center;
      cursor: pointer;
      strong {
        padding: 0 0 5px 0;
        display: block;
      }
      &.active,
      &:hover {
        border-bottom: 2px solid #e07c00;
      }
    }
  }
}
.feature-img-intro {
  width: 100%;
  padding-top: 56.25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

