<template>

  <div class="facility_page facility-list-page">

    <FacilityHeader />
    <div class="page-content py-3">

      <div class="filter-body-content mt-3">
        <aside class="filter-sidebar">
          <!-- <UserSidebarEn v-if="browserLg == 'en'" /> -->
          <!-- v-else-if="browserLg == 'ja'" -->
          <UserSidebarJP
            :areaData="selectedAreaI"
            :key="reloadJP"
          />
        </aside>
        <section class="filter-facility-list">
          <transition name="slide">
            <section class="search_result">
              <!-- #search result bar start  -->
              <div
                class="result-count"
                v-if="facilitiesLoading"
              >

                <nav class="f-breadcrumb">
                  <ol>
                    <li
                      v-for="(crumb, ci) in breadPram"
                      :key="ci"
                    >
                      <a
                        class="btn-link"
                        @click="getFacilitiesListByArea(crumb)"
                      >
                        <!-- :class="{ disabled: isLast(ci) }" -->
                        {{ crumb.name }}
                      </a>
                    </li>
                  </ol>
                </nav>
                <!-- <pre><code>{{fullAreaList}}</code></pre> -->
                <strong
                  class="selected-area-name"
                  v-if="isSearchByArea"
                >{{selectedAreaI.name}} の宿</strong>
                <strong
                  class="selected-area-name"
                  v-if="isSearchByName"
                >{{sQueryName}} の検索結果</strong>
                <strong
                  class="selected-area-name"
                  v-if="isSearchByTag"
                ><span
                    class="tag-list"
                    v-for="tag in sTagName"
                    :key="tag"
                  >{{tag}}</span> のタグ検索結果</strong>
                <!-- v-if="facilitiesLoading" -->
                <CRow class="border-bottom p-0 mx-0 mb-3">
                  <!-- <CCol class="pt-2">
                    <span>{{ $t("facilitySearchPage.totalNumber") }}</span>: <strong>{{ facilityCount }}</strong>
                  </CCol> -->
                  <CCol class="pt-2 px-0">
                    <!-- <span class="ml-4">{{ $t("facilitySearchPage.resultNumber") }}</span>:  -->
                    <h5>{{ facilities.length }} 件 ( {{facilityNavInfo}}件 )</h5>
                  </CCol>
                </CRow>
              </div>

              <!-- {{page}} -->
              <!-- #search result bar end  -->
              <div
                class="f-list-section"
                v-if="facilitiesLoading"
              >
                <template v-for="facility in facilityInfoList">
                  <div
                    class="yado-list"
                    :key="facility.uniqueId"
                  >
                    <!-- <FacilityList :FacilityId="facility.uniqueId" /> -->
                    <FacilityList :FacilityInfo="facility" />

                  </div>
                  <!-- #result yado card region  -->

                </template>

                <CPagination
                  :activePage.sync="pagiStart"
                  :pages="totalFacilityPage"
                  align="center"
                />
                <!-- #endResult yado card region  -->
              </div>
              <!-- <pre><code>{{facilities}}</code></pre> -->
            </section>

          </transition>
          <div
            class="skeleton s-section"
            v-if="ifReqYado"
          >
            <header>
              <span class="skeleton-box s-f-header"></span>
            </header>
            <article
              class="skeleton yado-f-list"
              v-for="n in 10"
              :key="n"
            >
              <CRow class="m-0 p-0 ">
                <span class="skeleton-box s-title"></span>
              </CRow>
              <CRow class="m-0 p-0">

                <CCol class="p-0">
                  <span class="skeleton-box s-feature-image"></span>
                </CCol>
                <CCol class="px-3 py-0 details-sec">
                  <span class="skeleton-box s-address"></span>
                  <p>
                    <span
                      class="skeleton-box"
                      style="width:100%;"
                    ></span>
                    <span
                      class="skeleton-box"
                      style="width:83%;"
                    ></span>
                    <span
                      class="skeleton-box"
                      style="width:95%;"
                    ></span>
                    <span
                      class="skeleton-box"
                      style="width:70%;"
                    ></span>
                  </p>
                  <div class="show-details-btn">
                    <span
                      class="skeleton-box s-button"
                      style="width:100%;"
                    ></span>
                  </div>
                </CCol>
              </CRow>
            </article>
          </div>

          <transition name="fade">

            <CAlert
              v-if="facilityNotFound"
              color="danger"
            >{{ $t("facilitySearchPage.facilityNotFoundAlert") }}</CAlert>

            <!-- search_result end  -->
          </transition>
          <CAlert
            color="warning"
            v-if="!NotSelectedArea"
          >
            {{ $t("facilitySearchPage.selectSearch") }}
          </CAlert>

          <iframe
            v-if="!facilitiesLoading"
            id="parentframe"
            width="100%"
            src="https://yado-sagashi.com/liberty-resort/index.php"
            frameborder="no"
            scrolling="no"
          ></iframe>
          
        </section>
      </div>

    </div>
    <FacilityFooter />
  </div>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import config from "@/config";
// import UserSidebarEn from "./components/SidebarEN";
import UserSidebarJP from "./components/SidebarJP";
import FacilityHeader from "@/containers/FacilityHeader";
import FacilityFooter from "@/containers/FacilityFooter";

import FacilityList from "@/components/FacilityList";
import JQuery from "jquery";
let $ = JQuery;
window.addEventListener(
  "message",
  function (e) {
    var iframe = $("#parentframe");
    var eventName = e.data[0];
    var data = e.data[1];
    switch (eventName) {
      case "setHeight":
        iframe.height(data);
        break;
    }
  },
  false
);

export default {
  // components: { Loading },
  name: "FacilitySearch",
  components: {
    FacilityHeader,
    FacilityList,
    // UserSidebarEn,
    UserSidebarJP,
    FacilityFooter,
  },
  metaInfo() {
    return {
      title:
        this.isSearchByArea || this.isSearchByName
          ? `${this.selectedAreaI.name || this.sQueryName} ${i18n.t(
              "siteInfo.areaSearchPageTitle"
            )}`
          : `${i18n.t("siteInfo.siteTitle")}`,
      meta: [
        {
          name: "description",
          content: `${i18n.t("siteInfo.siteTitle")}`,
        },
        {
          property: "og:title",
          content:
            this.isSearchByArea || this.isSearchByName
              ? `${this.selectedAreaI.name || this.sQueryName}  ${i18n.t(
                  "siteInfo.areaSearchPageTitle"
                )}`
              : `${i18n.t("siteInfo.siteTitle")}`,
        },
        {
          property: "og:site_name",
          content: `${i18n.t("siteInfo.siteTitle")}`,
        },
        // {
        //   property: "og:description",
        //   content: this.facility.desciption,
        // },
        // {
        //   property: "og:image",
        //   content: config.IMAGE_SM_URL + this.intorImgFirst,
        // },
      ],
    };
  },
  data() {
    return {
      facilityNotFound: false,
      facilitiesLoading: false,
      ifGetYado: false,
      ifReqYado: false,
      isSearchByArea: false,
      isSearchByName: false,
      isSearchByTag: false,
      isBCRequest: false,
      TagNotFound: false,
      NotSelectedArea: false,
      sQueryName: "",
      sTagName: "",
      pagiStart: 1,
      pagiLimit: 10,
      crumbs: [],
      facilityCount: 0,
      reloadJP: 0,
      facilityPagenationSelected: 5,
      selectedAreaI: {},
      fullAreaListData: [],

      /** 宿検索結果 */
      facilities: [],

      /** リクエストに使う宿情報 */
      requestFacility: {
        uniqueId: "",
      },
    };
  },
  computed: {
    /** display total facility page */
    facilityInfoList() {
      var start = (this.pagiStart - 1) * this.pagiLimit;
      var end = start + this.pagiLimit;
      return this.facilities.slice(start, end);
    },

    /** list page navigation */
    facilityNavInfo() {
      var start =
        this.pagiStart == 1
          ? this.pagiStart
          : (this.pagiStart - 1) * this.pagiLimit + 1;

      var end = start + this.pagiLimit - 1;

      this.totalFacilityPage == this.pagiStart
        ? (end = this.facilities.length)
        : end;
      console.log("total" + this.totalFacilityPage + "end" + end);
      return `${start}-${end}`;
    },

    /** count total facilty pages */
    totalFacilityPage() {
      var defVal = this.facilities.length / this.pagiLimit;
      return Math.ceil(defVal);
    },

    /** get browser default language */
    browserLg() {
      return navigator.language;
    },

    /** generate breadcrumb list */
    breadPram() {
      return this.getAreaPath(this.fullAreaListData, this.selectedAreaI.areaId);
    },
  },

  methods: {
    /** 宿一覧取得リクエスト */
    getFacilities(urlRequest, keyName) {
      this.fullAreaListData = [];
      this.facilitiesLoading = false;
      this.isSearchByArea = false;
      this.isSearchByTag = false;
      this.ifReqYado = true;
      this.pagiStart = 1;
      this.sQueryName = keyName;
      // 宿が見つからない際のメッセージ表示フラグ
      this.facilityNotFound = false;

      axios
        .get(urlRequest)
        .then((resp) => {
          // console.log("Facility list**:" + JSON.stringify(resp.data));
          this.isSearchByName = true;
          //レスポンスを格納

          this.facilities = resp.data;
          this.ifReqYado = false;
          this.facilitiesLoading = true;
          this.NotSelectedArea = true;
          // this.collapse = true;
          this.facilities.length === 0
            ? ((this.collapse = false), (this.facilityNotFound = true))
            : ((this.collapse = true), (this.facilityNotFound = false));
        })
        .catch((err) => {
          console.log(err);
          this.facilityNotFound = true;
          this.ifReqYado = false;
        });
    },
    /** get full area list */
    getAllAreaListData() {
      this.fullAreaListData = [];
      var urlRequest = `/rest/area/arealist`;
      axios
        .get(urlRequest)
        .then((resp) => {
          this.fullAreaListData = resp.data.areas;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /** 宿の登録件数取得 */
    getFacilityTotalNumber() {
      //Login as registered user
      var urlRequest = `/rest/hotel`;
      // var urlRequest = `http://localhost:8080/rest/hotel`;
      axios
        .get(urlRequest)
        .then((resp) => {
          //レスポンスを格納
          this.facilityCount = resp.data.total;
          // console.log(this.facilityCount);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * get facilty list by area ID */
    getFacilitiesListByArea(selectedArea) {
      this.reloadJP++;
      this.facilitiesLoading = false;
      this.isSearchByName = false;
      this.isSearchByTag = false;
      this.ifReqYado = true;
      this.pagiStart = 1;
      this.selectedAreaI = selectedArea;
      if (this.$route.query.areaId) this.$router.replace({ query: null });
      if (this.$route.query.tagId) this.$router.replace({ query: null });

      // UserSidebarJP.methods.selecteArealC(selectedArea);

      /** get all area list */
      var urlRequest = `/rest/hotel/published?areaIds=${selectedArea.areaId}`;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.facilities = resp.data;
          this.ifReqYado = false;
          this.isBCRequest = false;
          this.facilitiesLoading = true;
          this.isSearchByArea = true;
          this.NotSelectedArea = true;
          this.facilities.length === 0
            ? ((this.collapse = false), (this.facilityNotFound = true))
            : ((this.collapse = true), (this.facilityNotFound = false));
        })
        .catch((err) => {
          console.log(err);
          this.facilityNotFound = true;
          this.ifReqYado = false;
        });
    },
    /**
     * get facilty list by area ID */
    getFacilitiesListByTags(selectedTags) {
      console.log(selectedTags);
      this.ifReqYado = true;

      this.fullAreaListData = [];
      this.sQueryName = "";
      this.sTagName = "";

      this.sTagName = selectedTags.map((a) => {
        return a.name;
      });

      var seletedTagId = [];
      selectedTags.forEach((a) => {
        seletedTagId.push(a.id);
      });

      this.reloadJP++;
      this.isSearchByTag = false;
      this.facilitiesLoading = false;
      this.isSearchByName = false;
      this.isSearchByArea = false;
      this.pagiStart = 1;
      if (this.$route.query.areaId) this.$router.replace({ query: null });
      if (this.$route.query.tagId) this.$router.replace({ query: null });

      // UserSidebarJP.methods.selecteArealC(selectedArea);

      /** get all area list */
      var urlRequest = `/rest/hotel/published?tagIds=${seletedTagId}`;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          this.facilities = resp.data;
          this.facilities.length === 0
            ? ((this.collapse = false), (this.facilityNotFound = true))
            : ((this.collapse = true), (this.facilityNotFound = false));

          this.ifReqYado = false;
          this.isBCRequest = false;
          this.facilitiesLoading = true;
          this.isSearchByTag = true;
          this.NotSelectedArea = true;
        })
        .catch((err) => {
          console.log(err);
          this.TagNotFound = true;
          this.ifReqYado = false;
          this.NotSelectedArea = true;
          this.facilityNotFound = true;
        });
    },

    /**
     *  find area in area list
     *
     */
    AreaLoop(arr, target, index, path) {
      if (arr[index].areaId === target) {
        path.push({ name: arr[index].name, areaId: arr[index].areaId });
      } else if (arr[index].childs.length) {
        path.push({ name: arr[index].name, areaId: arr[index].areaId });
        arr[index].childs.forEach((_, i, a) => {
          this.AreaLoop(a, target, i, path);
        });

        if (path[path.length - 1].areaId === arr[index].areaId) path.pop();
      }
    },
    /**
     *  get area path for breadcrumb
     *
     */
    getAreaPath(arr, target) {
      let path = [];
      arr.forEach((_, i, a) => this.AreaLoop(a, target, i, path));
      return path;
    },

    /**
     * get facility list by query request
     *
     */
    isQueryByLink() {
      //if have an area query
      if (this.$route.query.areaId) {
        this.isBCRequest = true;

        var areaData = {
          name: this.$route.query.areaName,
          areaId: this.$route.query.areaId,
        };
        this.getFacilitiesListByArea(areaData);
      }

      // if have a tag
      if (this.$route.query.tagId) {
        var tagData = [
          {
            name: this.$route.query.tagName,
            id: this.$route.query.tagId,
          },
        ];
        console.log("get tagg**" + JSON.stringify(tagData));
        this.getFacilitiesListByTags(tagData);
      }
    },

    /**
     * go to xserver page */
    goXserver() {
      window.location.href = config.WPSITE;
    },
  },
  beforeMount() {
    //宿の登録件数取得
    this.isQueryByLink();
    this.getFacilityTotalNumber();
    this.getAllAreaListData();
  },
};
</script>
<style lang="scss" scoped>
#suggested-list {
  width: 100%;
  min-height: 920px;
  border: none;
}
#parentframe{
  min-height: 670px;
}
.filter-facility-list .alert {
  max-width: 750px;
}
.filter-body-content {
  display: flex;
  .filter-sidebar {
    width: 28%;
    float: left;
    margin-right: 2%;
  }
  .filter-facility-list {
    width: 70%;
    float: right;
  }
}
.facility-list-page {
  .page-content {
    min-height: 64vh;
  }
}
.selected-area-name {
  font-size: 1.5em;
  padding: 10px 0;
  display: block;
}
.yado-search {
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    padding: 0 7px;
  }
}
.count-srh {
  margin-top: 12px;
  padding: 0 0.5rem;
}
.s-f-header {
  width: 100%;
  height: 4.5em !important;
  margin-bottom: 2em;
}
.yado-f-list {
  margin: 0 0 2em;
  .s-feature-image {
    height: 180px !important;
    width: 100%;
  }
  .s-title {
    height: 2.3em !important;
    width: 86%;
    margin-bottom: 1em;
  }
  .s-address {
    height: 0.8em !important;
    width: 90%;
    margin-bottom: 0.6em;
  }

  .s-button {
    height: 2em !important;
  }
}
.tag-list {
  margin-right: 4px;
  &::after {
    content: "、";
  }
  &:last-child::after {
    content: "";
  }
}
// animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 700px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

@media only screen and (max-width: 767px) {
  #suggested-list {
    min-height: 1128px;
  }
}
</style>