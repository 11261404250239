<template>
  <div class="edit_options">
    <!-- <h4 class="sub-title">{{ $t("yadoEdit.facilityTag.title") }}</h4> -->

    <!-- select facility tag  -->
    <div class="submit_form tag-list-section">
      <CRow
        class=" py-2"
        v-for="(tagsList,i) in tagsLists"
        :key="i"
      >
        <CCol
          md="3"
          class="label"
        >
          <strong class="h-100">{{ tagsList.typeName }}</strong>
        </CCol>
        <CCol>
          <div class="form-inline bg-light p-1 rounded">
            <CInputCheckbox
              class="mx-1"
              v-for="(tag, n) in tagsList.tags"
              :key="n + tag"
              :label="tag.name"
              :value="tag.name"
              custom
              :checked.sync="tag.enabled"
            />
          </div>
        </CCol>
      </CRow>
      <!-- .tab_footer end  -->

    </div>
    <!-- edit_form end -->

    <!-- .tab_footer end  -->

  </div>
  <!-- edit_options -->
</template>

<script>
// import i18n from "../i18n";
import axios from "axios";
export default {
  name: "FacilityTags",
  components: {},
  props: {
    UniqueId: {
      type: String,
    },
  },
  data() {
    return {
      successModal: false,
      errorModal: false,
      amenityModal: false,
      featureModal: false,
      /**宿が設定している宿タグリスト */
      ownTags: [],
      errorMessage: "",
      reload: 1,
      /**宿探しに登録された全てのタグ情報 */
      tagsLists: [],
      /**レスポンス */
      tags: [],
      /**タイプ */
      types: [
        {
          // 宿形態
          value: 1,
          label: `宿形態`,
        },
        {
          // 風呂
          value: 2,
          label: `風呂`,
        },
        {
          // 温泉
          value: 3,
          label: `温泉`,
        },
        {
          // 泉質
          value: 4,
          label: `泉質`,
        },
        {
          // 料理
          value: 5,
          label: `料理`,
        },
        {
          // 食材
          value: 6,
          label: `食材`,
        },
        {
          // 部屋
          value: 7,
          label: `部屋`,
        },
        {
          // 施設・設備
          value: 8,
          label: `施設・設備`,
        },
        {
          // レジャー・目的
          value: 9,
          label: `レジャー・目的`,
        },
        {
          // 宿の人
          value: 10,
          label: `宿の人`,
        },
        {
          // 立地
          value: 11,
          label: `立地`,
        },
      ],
    };
  },

  methods: {
    // /** 有効無効切替 */
    getRequest() {
      // 初期化
      this.tagsLists = [];
      this.ownTags = [];
      this.tags = [];

      const urlRequest = `/rest/tag`;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log("All tags:" + JSON.stringify(resp.data.tags));
          this.tags = resp.data.tags;
          // this.getRelationTags(this.tags);
          this.sortTypeTags(this.tags);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * Sort tags by type
     */
    sortTypeTags(allTags) {
      this.types.forEach((a) => {
        const type = a.value;
        const label = a.label;
        const tags = allTags.filter((b) => b.type == type);
        // this.tagsLists.push(tags);
        this.tagsLists.push({ typeName: label, tags: tags });
      });
      // console.log("all tagsLists"+JSON.stringify(this.tagsLists))
    },
    /**
     * post relation tag
     */
    putRelationTags() {
      // 表示している中でenabledがtrueのタグ情報だけリクエストする
      var body = [];
      this.tagsLists.forEach((a) => {
        const requests = a.tags.filter((b) => b.enabled);
        body.push(...requests);
      });
      // console.log(body);
      this.$emit("selected-tags", body);
    },
  },
  beforeMount() {
    this.getRequest();
  },
  updated() {
    this.putRelationTags();
  },
};
</script>
<style lang="scss" scoped>
.tag-list-section {
  .row .label {
    max-width: 100%;
  }
}
</style>